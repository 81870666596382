<template>
  <div v-if="referral">
    <div class="backcover">
      <header class="pt-4">
        <div class="container mob-container">
          <div class="row">
            <div class="col">
              <router-link to="/">
                <img src="/images/logo_h.svg" class="header-img-logo" alt="logo"/>
              </router-link>
            </div>
            <div class="col-auto">
              <a href="https://skilllms.com" class="btn-themed btn-semitransparent" target="_blank">{{ $t('to_school_site') }}</a>
            </div>
          </div>
        </div>
      </header>
      <div class="container mob-container">
        <h1 class="text-white text-center">{{ $t('your_friend_invites_you', { name: referral.user.name }) }}</h1>
      </div>
    </div>
    <div class="container mob-container">
      <div class="spacecard blue top-card">
        <h2 class="pt-3">{{ $t('leave_your_data_and_we_contact_you') }}</h2>
        <div class="text-muted">{{ $t('first_lesson_free_and_we_tell') }}</div>
        <div class="spacecard border-0 mt-3">
          <div class="row">
            <div class="col-lg">
              <label>{{ $t('name') }}</label>
              <InputGroup v-model="formData.name" :label="$t('name')"/>
            </div>
            <div class="col-lg">
              <label>{{ $t('phone') }}</label>
              <PhoneGroup v-model="formData.phone" :label="$t('phone')"/>
            </div>
            <div class="col-lg">
              <label>{{ $t('email') }}</label>
              <InputGroup v-model="formData.email" :label="$t('email')"/>
            </div>
            <div class="col-lg">
              <label class="d-lg-inline-block d-none">&nbsp;</label>
              <div class="mb-lg-0 mb-3 mt-lg-0 mt-2">
                <button @click="submitForm" :disabled="loading" class="btn-themed w-100">{{ $t('send') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 text-muted" v-html="$t('by_pressing_you_agree')"></div>
      </div>
    </div>
    <footer>
      <div class="container mob-container text-center">
        ® "SkillLMS"Свидетельство на товарный знак №81277 от РГП «Национальный институт интеллектуальной собственности» МЮ РК г. Астана
      </div>
    </footer>
  </div>
</template>

<script>
import { ReferralService } from "../../../services/api.service"
import InputGroup from "../../parts/general/form/InputGroup.vue"
import PhoneGroup from "../../parts/general/form/PhoneGroup.vue"

export default {
  name: "Referral",
  components: {
    InputGroup,
    PhoneGroup
  },
  metaInfo() {
    return {
      title: this.$t('referral_system'),
    }
  },
  data() {
    return {
      referral: null,
      formData: {
        name: '',
        email: '',
        phone: '',
      }
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading
    }
  },
  methods: {
    getReferralInfo() {
      ReferralService.getReferralInfo(this.$route.params.hash).then(res => {
        this.referral = res.data.data
      })
    },
    async submitForm() {
      try {
        const res = await ReferralService.submitReferralForm(this.referral.id, this.formData)
        this.$swal({
          title: this.$t('your_application_sent_successfully'),
          text: this.$t('well_contact_soon'),
          confirmButtonText: this.$t('good'),
          customClass: { image:'swal-image-confirm' },
          imageUrl: '/images/tick2.png',
        }).then(() => {
          location.href = 'https://skilllms.com'
        })
        console.log(res.data)
      } catch (e) {
        this.errResponse(e)
      }

    }
  },
  mounted() {
    this.getReferralInfo()

  }
}
</script>

<style scoped lang="scss">
.backcover {
  background: url('/images/referral_back.jpg') no-repeat center;
  background-size: cover;
  .header-img-logo {
    max-width: 107px;
  }
  h1 {
    padding-top: 110px;
    padding-bottom: 220px;
  }
  .btn-semitransparent {
    background: rgba(255, 255, 255, 0.4);
  }
}
.top-card {
  position: relative;
  top: -130px;
  margin-bottom: -20px;
}
label {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 3px;
}
footer {
  color: #94A0B1;
  font-size: 13px;
  padding: 25px 0;
  border-top: 1px solid #E6EAF2;
}

@media(max-width: 768px) {
  .top-card {
    margin-bottom: -80px;
  }
  h1 {
    font-size: 26px;
  }
}
</style>
